<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="code"
          :label="placeholder"
          class="c-input-small"
          dense
          outlined
          clearable
          hide-details
          autofocus
          @keyup.enter="inputScan"
          append-icon="mdi-qrcode-scan"
          @click:append="showQRCodeScan('code')"
        ></v-text-field>
      </v-col>
      <v-col cols="12" class="text-center" v-if="currentStowingItem">
        <v-alert color="green" text dark class="text-h4 mb-0"
          >{{ $t("labels.basket") }}:
          {{ currentStowingItem.basket_barcode }}</v-alert
        >
        <v-alert color="secondary" text dark class="mb-0">
          <div class="text-h5 error--text">
            {{ goods.sku }} - {{ goods.customer_goods_barcode }}
          </div>
          <div class="text-h6">{{ goods.sub_barcode }}</div>
          <div>{{ goods.name }}</div>
        </v-alert>
        <v-alert color="error" text dark>
          <div class="black--text">{{ goods.storage_location }}</div>
          <div class="font-weight-bold">
            {{ currentStowingItem.location.join("; ") }}
          </div>
        </v-alert>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="currentCell"
          :label="$t('labels.current_position_suggest')"
          dense
          outlined
          clearable
          hide-details
          @keyup.enter="scanSuggest"
        ></v-text-field>
      </v-col>
      <v-col cols="12" v-if="suggestCells.length > 0 || goodsCell">
        <v-alert color="warning" text dark class="text-center">
          <div class="black--text font-italic" v-if="suggestCells.length > 0">
            <span>{{ $t("labels.suggest") }}: </span>
            <span v-for="sc in suggestCells" :key="`sc_${sc.cell_id}`">
              <span class="error--text font-weight-bold">{{ sc.cell_id }}</span
              >;
            </span>
          </div>
          <div class="black--text font-italic" v-if="goodsCell">
            <span class="error--text font-weight-bold">
              {{ goodsCell.cell_scanned }}
            </span>
            - {{ goodsCell.name }} -
            <span class="font-weight-bold">
              {{ goodsCell.customer_goods_barcode }}
            </span>
            - {{ $t("labels.wait_pickup_1") }}:
            <span class="font-weight-bold">{{ goodsCell.wait_pickup }}</span> -
            {{ $t("labels.available") }}:
            <span class="font-weight-bold">{{ goodsCell.available }}</span>
          </div>
        </v-alert>
      </v-col>
      <v-col cols="12">
        <div class="d-flex align-center justify-center" v-if="goods.url_images">
          <ImageViewer width="300px" height="auto" :url="goods.url_images" />
        </div>
      </v-col>

      <v-col cols="12">
        <v-btn color="warning" block @click="showDetailDialog">
          {{ $t("labels.command_view") }}
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog v-model="detailDialog" persistent max-width="600px">
      <v-card>
        <v-card-title class="text-h6 mb-3">
          {{ $t("labels.command_detail") }}
        </v-card-title>

        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead class="v-data-table-header">
                <tr>
                  <th class="text-center">{{ $t("labels.status") }}</th>
                  <th class="text-center">{{ $t("labels.basket") }}</th>
                  <th class="text-center">{{ $t("labels.sku") }}</th>
                  <th class="text-center">{{ $t("labels.position") }}</th>
                </tr>
              </thead>
              <tbody v-for="(item, index) in items" :key="`value_${index}`">
                <tr
                  v-for="jtem in item.goods_receipt_basket"
                  :key="jtem.id"
                  class="text-center"
                  :class="{
                    'error--text': jtem.status == 0,
                    'info--text': jtem.status == 1,
                  }"
                >
                  <td>
                    {{
                      jtem.status == 0
                        ? $t("labels.waiting_stowing")
                        : jtem.status == 1
                        ? $t("labels.process_stowing")
                        : $t("labels.finish_stowing")
                    }}
                  </td>
                  <td>{{ jtem.basket_barcode }}</td>
                  <td>{{ jtem.goods_sku }}</td>
                  <td>{{ jtem.location && jtem.location.join("; ") }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="hideDetailDialog">
            {{ $t("labels.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="qrScanDialog" max-width="100vw">
      <QRCodeScanner
        v-if="qrScanDialog"
        :name="qrScanType"
        :auto-close="false"
        @close="hideQRCodeScan"
        @onScanned="onQRScanned"
      />
    </v-dialog>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "GoodsReceiptStowing",
  components: {
    ImageViewer: () => import("@/components/goods/ImageViewer"),
    QRCodeScanner: () => import("@/components/common/QRCodeScanner"),
  },
  props: {
    activeCommand: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    detailCommand: {},
    goods: {},
    isLoading: false,
    code: null,
    step: 1,
    currentCell: null,
    suggestCells: [],
    goodsCell: null,
    detailDialog: false,
    qrScanDialog: false,
    qrScanType: null,
  }),
  computed: {
    placeholder() {
      if (!this.currentStowingItem) {
        return this.$t("labels.scan_basket_code");
      } else {
        switch (this.step) {
          case 1:
            return this.$t("labels.qr_sku_sub_barcode");
          case 2:
            if (this.currentStowingItem.location.length > 0) {
              return this.$t("labels.scan_basket_or_position");
            } else {
              return this.$t("labels.scan_position");
            }
          default:
            return "";
        }
      }
    },
    items() {
      const data = { ...this.detailCommand };
      const items = (data.items && JSON.parse(data.items)) || [];
      return [...items];
    },
    /*currentItem() {
      return this.items.length > 0 && this.items.find(i => i.status === 1) || null
    },
    currentItemBaskets() {
      return this.currentItem && this.currentItem.goods_receipt_basket || []
    },*/
    currentStowingItem() {
      let currentItem = null;
      this.items.forEach((receiptItem) => {
        receiptItem.goods_receipt_basket.forEach((grb) => {
          if (grb.status === 1) {
            currentItem = grb;
          }
        });
      });
      return currentItem;
      //this.currentItemBaskets.length > 0 && this.currentItemBaskets.find(i => i.status === 1) || null
    },
    currentGoodsSku() {
      return (
        (this.currentStowingItem && this.currentStowingItem.goods_sku) || null
      );
    },
  },
  watch: {
    currentGoodsSku() {
      this.getGoodsData();
    },
  },
  methods: {
    showQRCodeScan(type) {
      this.qrScanDialog = true;
      this.qrScanType = type;
    },
    hideQRCodeScan() {
      this.qrScanDialog = false;
      this.qrScanType = null;
    },
    onQRScanned(filter) {
      this[filter.name] = filter.value;
      this.inputScan();
    },
    async getGoodsData() {
      if (!this.currentGoodsSku) {
        this.goods = {};
        return false;
      }
      const { data } = await httpClient.post(
        "/goods/v1/get-by-sku-for-receipt-stowing",
        {
          sku: this.currentGoodsSku,
          id_warehouse: this.detailCommand.id_warehouse,
        }
      );
      this.goods = { ...data };
    },
    async getDetailCommand() {
      if (this.isLoading) {
        this.isLoading = false;
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post(
          "/goods-receipt/v1/get-detail-command",
          {
            id_command: this.activeCommand.id,
          }
        );
        this.detailCommand = { ...data };
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },

    async inputScan() {
      if (!this.currentStowingItem) {
        await this.scanBasketCode();
      } else {
        switch (this.step) {
          /*case 1:
            await this.scanBasketCode()
            break;*/
          case 1:
            await this.scanSku();
            break;
          case 2:
            if (this.code == this.currentStowingItem.basket_barcode) {
              await this.confirmBasket();
            } else {
              await this.scanCell();
            }
            break;
          default:
            return "";
        }
      }
    },

    async scanBasketCode() {
      if (!this.currentStowingItem) {
        let receiptItem = null;
        let basketItem = null;
        const items = [...this.items];
        items.forEach((item) => {
          const stowingItem = item.goods_receipt_basket.find(
            (grb) => grb.basket_barcode == this.code
          );
          if (stowingItem) {
            receiptItem = item;
            basketItem = stowingItem;
          }
        });
        if (!receiptItem || !basketItem) {
          this.code = null;
          this.$vToastify.error(this.$t("messages.basket_code_not_found"));
          document.getElementById("error_sound_player").play();
          return false;
        }
        if (receiptItem.status !== 0 && receiptItem.status !== 1) {
          this.code = null;
          this.$vToastify.error(this.$t("messages.wrong_tracking_status"));
          document.getElementById("error_sound_player").play();
          return false;
        }
        if (basketItem.status !== 0) {
          this.code = null;
          this.$vToastify.error(this.$t("messages.wrong_basket_status"));
          document.getElementById("error_sound_player").play();
          return false;
        }

        if (this.isLoading) {
          this.$vToastify.warning(this.$t("messages.loading"));
          return false;
        }
        this.isLoading = true;

        try {
          const { data } = await httpClient.post(
            "/goods-receipt/v1/scan-stowing-basket",
            {
              id_command: this.detailCommand.id,
              id_goods_receipt_basket: basketItem.id,
            }
          );
          this.code = null;
          this.isLoading = false;
          this.detailCommand = { ...data };
          document.getElementById("success_sound_player").play();
        } catch (e) {
          this.code = null;
          this.isLoading = false;
          const errMsg =
            (e.response &&
              e.response.data &&
              e.response.data.error &&
              e.response.data.error.message) ||
            null;
          document.getElementById("error_sound_player").play();
          this.$vToastify.error(errMsg);
        }
      }
    },

    async scanSku() {
      try {
        const { data } = await httpClient.post("/goods/v1/get-by-code-or-uid", {
          code: this.code,
          id_goods: this.goods.id,
        });
        if (!data) {
          this.$vToastify.error(
            this.$t("messages.wrong_type", { type: this.placeholder })
          );
          this.code = null;
          this.isLoading = false;
          document.getElementById("error_sound_player").play();
          return false;
        }
        this.step = 2;
        this.code = null;
        this.isLoading = false;
        document.getElementById("success_sound_player").play();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.code = null;
        this.isLoading = false;
        document.getElementById("error_sound_player").play();
      }
      /*const goodsCodes = [''+this.goods.sku, this.goods.customer_goods_barcode]
      if (this.goods.sub_barcode){
        goodsCodes.push(this.goods.sub_barcode)
      }
      if (goodsCodes.includes(this.code)) {
        this.step = 2
        this.code = null
        document.getElementById('success_sound_player').play()
      } else {
        this.code = null
        this.$vToastify.error(`${this.placeholder} không đúng`)
        document.getElementById('error_sound_player').play()
      }*/
    },

    async scanCell() {
      if (this.currentStowingItem.location.includes(this.code)) {
        this.code = null;
        this.$vToastify.error(this.$t("messages.position_scanned"));
        document.getElementById("error_sound_player").play();
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post(
          "/goods-receipt/v1/scan-stowing-cell",
          {
            cell_id: this.code,
            id_command: this.detailCommand.id,
            id_goods_receipt_basket: this.currentStowingItem.id,
            id_goods: this.goods.id,
          }
        );
        this.detailCommand = { ...data };
        this.code = null;
        this.isLoading = false;
        document.getElementById("success_sound_player").play();
      } catch (e) {
        this.code = null;
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        document.getElementById("error_sound_player").play();
        this.$vToastify.error(errMsg);
      }
    },

    async confirmBasket() {
      if (
        !this.currentStowingItem.location ||
        this.currentStowingItem.location.length === 0
      ) {
        this.code = null;
        document.getElementById("error_sound_player").play();
        this.$vToastify.error(this.$t("messages.position_not_scanned"));
        return false;
      }
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post(
          "/goods-receipt/v1/confirm-stowing-basket",
          {
            id_command: this.detailCommand.id,
            id_goods_receipt_basket: this.currentStowingItem.id,
          }
        );
        this.code = null;
        this.isLoading = false;
        this.detailCommand = { ...data };
        this.step = 1;
        document.getElementById("success_sound_player").play();
        if (this.detailCommand.status === 2) {
          this.commandDone();
        }
      } catch (e) {
        this.code = null;
        this.isLoading = false;
        document.getElementById("error_sound_player").play();
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },

    commandDone() {
      this.$emit("done", true);
    },
    async scanSuggest() {
      await this.suggestByCell();
      await this.goodsByCell();
      this.currentCell = null;
    },
    async suggestByCell() {
      try {
        const { data } = await httpClient.post(
          "/warehouse-location/v1/suggest-by-cell",
          {
            id_warehouse: this.detailCommand.id_warehouse,
            cell_id: this.currentCell,
            id_goods: this.goods.id,
            id_goods_receipt_basket: this.currentStowingItem.id,
          }
        );
        this.suggestCells = data && data.length > 0 ? [...data] : [];
        document.getElementById("success_sound_player").play();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        document.getElementById("error_sound_player").play();
        this.$vToastify.error(errMsg);
      }
    },
    async goodsByCell() {
      try {
        const { data } = await httpClient.post(
          "/warehouse-location/v1/get-goods-by-cell",
          {
            id_warehouse: this.detailCommand.id_warehouse,
            cell_id: this.currentCell,
          }
        );
        this.goodsCell = data ? { ...data } : null;
        document.getElementById("success_sound_player").play();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        document.getElementById("error_sound_player").play();
        this.$vToastify.error(errMsg);
      }
    },
    showDetailDialog() {
      this.detailDialog = true;
    },
    hideDetailDialog() {
      this.detailDialog = false;
    },
  },
  mounted() {
    this.getDetailCommand();
  },
};
</script>

<style scoped></style>
